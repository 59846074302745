import { message } from "antd";

export async function httpRequest<T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  body: any = null,
): Promise<{ status: number | 'error'; data: T }> {
  
  let userAgent: any = localStorage.getItem('centerofcode_user')

  if (userAgent != null) {
    userAgent = JSON.parse(userAgent)
  } else {
    userAgent = null
  }
  
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'text/plain',
      'token': userAgent ? userAgent.token : ''
    }
  };

  let query = null

  if (method == 'POST' || method == 'PUT') {
    options.body = JSON.stringify(body)
  } else {
    if (body != null) {
      query = '?' + new URLSearchParams(body).toString()
    }
  }
  
  try {
    const response = await fetch('https://api.trackltup.com:2087/dealer/' + url + (query != null ? query : ''), options)
    const status: any = response.status
    const data = await response.json()

    if (status == '403') {
      message.error(`${data.text} (${status}) WEBSERICES (${url.split('/')[1]})`)
    }

    return { status, data }
  } catch (error: any) {
    console.error('HTTP request failed', error)
    return { status: 'error', data: error.message }
  }
}



// export async function httpRequest<T>(
//   url: string,
//   method: 'GET' | 'POST' | 'PUT' | 'DELETE',
//   body: any = null,
//   onUploadProgress: (percent: number) => void = () => {}
// ) {

//   // Get the user token from local storage
//   let userAgent: any = localStorage.getItem('centerofcode_user');

//   if (userAgent != null) {
//     userAgent = JSON.parse(userAgent);
//   } else {
//     userAgent = null;
//   }

//   // For POST and PUT methods with body, we use XMLHttpRequest to track upload progress
//     if (method === 'POST' || method === 'PUT') {
//       return new Promise((resolve, reject) => {
//         const xhr = new XMLHttpRequest();
//         xhr.open(method, `https://api.trackltup.com:2087/dealer/${url}`, true);
//         xhr.setRequestHeader('Content-Type', 'text/plain');
//         if (userAgent) {
//           xhr.setRequestHeader('token', userAgent.token);
//         }

//         // Track upload progress
//         xhr.upload.onprogress = (event) => {
//           if (event.lengthComputable) {
//             const percentComplete = (event.loaded / event.total) * 100;
//             onUploadProgress(percentComplete);
//           }
//         };

//         // Handle the response
//         xhr.onreadystatechange = () => {
//           if (xhr.readyState === XMLHttpRequest.DONE) {
//             if (xhr.status >= 200 && xhr.status < 300) {
//               const responseData = JSON.parse(xhr.responseText);
//               resolve({ status: xhr.status, data: responseData });
//             } else {
//               reject({ status: xhr.status, data: xhr.responseText });
//             }
//           }
//         };

//         // Send the request
//         xhr.send(JSON.stringify(body));
//       });
//     }

//     const options: RequestInit = {
//       method,
//       headers: {
//         'Content-Type': 'application/json',
//         'token': userAgent ? userAgent.token : ''
//       },
//       body: (method === 'GET' || method === 'DELETE') ? null : JSON.stringify(body),
//     };
  
//     const response = await fetch(`https://api.trackltup.com:2087/dealer/${url}?${new URLSearchParams(body).toString()}`, options);
    
//     if (!response.ok) {
//       const errorText = await response.text(); // Get the error message
//       throw new Error(`HTTP error! Status: ${response.status} - ${errorText}`);
//     }

//     const responseData = await response.json();

//     if (!responseData) {
//       return { status: 200, data: [] };
//     }

//     return { status: response.status, data: responseData};
// }



// import React, { useEffect, useState } from 'react'

// type ApiResponse = {
//   key: string;
//   value: string;
// }

// const MyComponent: React.FC = () => {
//   const [response, setResponse] = useState<{ status: number | 'error'; data: ApiResponse | null }>({
//     status: 0,
//     data: null
//   })

//   useEffect(() => {
//     const fetchData = async () => {
//       const result = await httpRequest<ApiResponse>('https://api.example.com/resource', 'GET')
//       setResponse(result)
//     };

//     fetchData()
//   }, [])

//   return (
//     <div>
//       <h1>API Response</h1>
//       {response.status === 'error' ? (
//         <p>Error: {response.data}</p>
//       ) : (
//         <pre>{JSON.stringify(response.data, null, 2)}</pre>
//       )}
//     </div>
//   );
// };

// export default MyComponent;
